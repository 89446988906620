body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  width: 300px;
  min-width: 0;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

input:focus {
  border-color: #4096ff;
  box-shadow: 0 0 0 2px rgb(5 145 255 / 10%);
  border-inline-end-width: 1px;
  outline: 0;
}

form {
  max-width: 324px;
}
